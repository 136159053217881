<script setup>
import DataSets from "@/components/DataSets/dataSets";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "@/core/services/store";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import { useReporting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/Reporting/useReporting";
import { ReportingActions } from "@/components/ExternalApps/SalesChannelManagementApp/utility/enum";
import { useReportingSync } from "@/components/ExternalApps/SalesChannelManagementApp/composables/Reporting/useReportingSync";
import Modal from "@/components/Tools/Modal.vue";
import TableHelperQuickFilter from "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/TableHelperQuickFilter.vue";
import { useReportingQuickFilter } from "@/components/ExternalApps/SalesChannelManagementApp/composables/Reporting/useReportingQuickFilter";
import { featureDefaultValues } from "@/components/ExternalApps/SalesChannelManagementApp/config/configQuickFilter";

const store = useStore();
const data = ref([]);
const meta = ref({});

const allRowsSelected = ref(false);
const requestParams = store.getters["route/requestParams"];

const { activeFilterFeatures, featureEmptyValues, generateFeatureObject } =
  useReportingQuickFilter();
const {
  batchReportingTableActions,
  reportingTableActions,
  reportingTableHeaders
} = useReporting();
const {
  onOpenBatchSync,
  showSyncModal,
  selectedReportingAction,
  selectedReportingBatchAction,
  onOpenSync,
  executeSync,
  executeBatchSync,
  showSyncBatchModal
} = useReportingSync();

const search = ref("null");
const quickFilterModel = ref({});
const quickFilterDefaultModel = generateFeatureObject(featureDefaultValues);
const quickFilterParameters = ref({});
const isFiltered = ref(false);

const CFL_DATASET_ID = computed(
  () => store.getters.environmentVariables.CFLDataSetId
);

const orderByFields = {
  baseDataLIC: "baseDataLIS",
  stockLIC: "stockLIS",
  priceLIC: "priceLIS"
};

const executeStages = async (params, init = false) => {
  let payloadQueue = {
    key: "execute-data-set"
  };

  const orderBy = getOrderBy(params);

  if (init) {
    payloadQueue.group = "product-selection";
  }

  addEventToLoadingQueue(payloadQueue);
  const payload = getDataSetStagesPayload(orderBy, init);
  isFiltered.value = activeFilter.value || !!search.value;
  quickFilterParameters.value = payload.parameters;

  DataSets.execute(CFL_DATASET_ID.value, payload)
    .then(response => {
      meta.value = response.meta;
      data.value = response.data;
    })
    .finally(() => {
      removeEventFromLoadingQueue(payloadQueue);
    });
};

function getOrderBy(params) {
  return Object.entries(params)
    .map(([key, value]) => ({
      field: key.startsWith("orderBy")
        ? key.replace(/^orderBy\[|]$/g, "")
        : undefined,
      direction: value === "asc" ? 1 : -1
    }))
    .find(order => order.field !== undefined);
}

function filterEmptyValues(obj) {
  const filteredObj = {};

  for (const key in obj) {
    if (obj[key] !== "") {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}

function getDataSetStagesPayload(orderBy = {}, init = false) {
  let params = requestParams();
  let datasetParams = {
    ...quickFilterDefaultModel,
    ...{ search: search.value === "" ? "null" : search.value }
  };

  if (Object.keys(orderBy).length > 0) {
    datasetParams = {
      ...datasetParams,
      sortField: orderByFields?.[orderBy.field] ?? orderBy.field,
      sortDir: orderBy.direction
    };
  }

  if (!init) {
    datasetParams = {
      ...datasetParams,
      ...filterEmptyValues(quickFilterModel.value)
    };
  }

  let parameters = [];

  // DataSet accepts only string as value type
  for (const key in datasetParams) {
    parameters.push({
      name: key,
      type: "text",
      value: String(datasetParams[key])
    });
  }

  return {
    limit: params?.perPage ?? 15,
    page: params?.page ?? 1,
    parameters: parameters
  };
}

const activeFilter = computed(() => {
  for (const value of Object.values(quickFilterModel.value)) {
    if (value) return true;
  }
  return false;
});

function resetFilter() {
  quickFilterModel.value = generateFeatureObject(featureEmptyValues);
  executeStages({}, false);
}

onMounted(() => {
  executeStages({}, true);
});
</script>

<template>
  <div>
    <TableHelper
      ref="journalTable"
      :actions="reportingTableActions"
      :buttons="batchReportingTableActions"
      :fields="reportingTableHeaders"
      :items="data"
      :meta="meta"
      :use-card="false"
      actions-fixed
      disable-skeleton-loading
      group-key="product-selection"
      loading-key="execute-data-set"
      select-all-option
      :allow-single-select="!store.getters.disableJournalSingleExport"
      @search="search = $event"
      @reload-data="executeStages($event, false)"
      @select-all-rows-in-data="allRowsSelected = $event"
      @sync-price="onOpenSync(ReportingActions.PriceSync, $event)"
      @sync-product="onOpenSync(ReportingActions.ProductSync, $event)"
      @sync-stock="onOpenSync(ReportingActions.StockSync, $event)"
      @sync-prices="onOpenBatchSync(ReportingActions.PriceSync)"
      @sync-products="onOpenBatchSync(ReportingActions.ProductSync)"
      @sync-stocks="onOpenBatchSync(ReportingActions.StockSync)"
      @sync-offers="onOpenBatchSync(ReportingActions.OfferSync)"
    >
      <template #afterToolbar>
        <TableHelperQuickFilter
          v-for="feature in activeFilterFeatures"
          :key="feature"
          v-model="quickFilterModel"
          :feature="feature"
          @input="quickFilterModel = $event"
          @execute-filter="executeStages(false)"
        ></TableHelperQuickFilter>
        <button
          v-if="activeFilter"
          class="btn btn-sm btn-hover-light-primary"
          @click="resetFilter"
        >
          <i class="fal fa-filter-slash p-0 fa-lg"></i>
        </button>
      </template>
    </TableHelper>
    <Modal
      v-if="selectedReportingBatchAction"
      :show-modal="showSyncBatchModal"
      :title="
        $t(
          'salesChannelManagementApp.reporting.' +
            selectedReportingBatchAction?.description
        )
      "
      @toggle-modal="showSyncBatchModal = $event"
    >
      <template #content>
        <div>
          {{
            $t(
              "salesChannelManagementApp.reporting." +
                selectedReportingBatchAction?.description +
                "DescriptionBatch"
            )
          }}
        </div>
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            @click="
              executeBatchSync(
                allRowsSelected,
                $refs.journalTable?.selectedRows.map(row => row.id),
                quickFilterParameters,
                isFiltered
              )
            "
          >
            {{
              $t(
                "salesChannelManagementApp.reporting." +
                  selectedReportingBatchAction?.description +
                  "Start"
              )
            }}
          </button>
        </div>
      </template>
    </Modal>
    <Modal
      v-if="selectedReportingAction"
      :show-modal="showSyncModal"
      :title="
        $t(
          'salesChannelManagementApp.reporting.' +
            selectedReportingAction?.description
        )
      "
      @toggle-modal="showSyncModal = $event"
    >
      <template #content>
        <div>
          {{
            $t(
              "salesChannelManagementApp.reporting." +
                selectedReportingAction?.description +
                "Description"
            )
          }}
        </div>
      </template>

      <template #footer>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="executeSync">
            {{
              $t(
                "salesChannelManagementApp.reporting." +
                  selectedReportingAction?.description +
                  "Start"
              )
            }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped></style>
