<script setup>
import { useReportingSync } from "@/components/ExternalApps/SalesChannelManagementApp/composables/Reporting/useReportingSync";
import { onMounted } from "vue";
import { ReportingActions } from "@/components/ExternalApps/SalesChannelManagementApp/utility/enum";
import { Confirmation } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";

const { selectedReportingAction, executeSync } = useReportingSync();

async function onSyncClick() {
  const params = {
    title: i18n.t("salesChannelManagementApp.reporting.executeCategorySync"),
    cancelButtonText: i18n.t("general.cancel"),
    confirmButtonText: i18n.t("general.confirm")
  };

  const result = await Confirmation(params);
  if (!result.isConfirmed) return;

  await executeSync();
}

onMounted(() => {
  selectedReportingAction.value = ReportingActions.CategorySync;
});
</script>

<template>
  <div class="row row--dense">
    <div class="col-md-6">
      <div class="alert alert-info">
        {{
          $t(
            "salesChannelManagementApp.reporting." +
              selectedReportingAction?.description +
              "Description"
          )
        }}
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-primary" @click="onSyncClick">
        <i class="fal fa-play" />

        {{
          $t(
            "salesChannelManagementApp.reporting." +
              selectedReportingAction?.description +
              "Start"
          )
        }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
