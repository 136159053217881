import i18n from "@/core/plugins/vue-i18n";

const featuresAvailable = ["product", "stock", "prices", "type"];
const featureDefaultValues = {
  onlyError: "false",
  onlySuccess: "false",
  onlyNotExported: "false",
  lastImportedFrom: "1970-01-01 00:00:00",
  lastImportedTo: "2070-01-01 00:00:00" // approved by Larsagne
};
const articleEmptyValues = {
  onlyError: false,
  onlySuccess: false,
  onlyNotExported: false,
  lastImportedFrom: "",
  lastImportedTo: ""
};
const typeEmptyValues = {
  onlyVariants: false,
  noVariants: false
};

const stateEmptyValues = {
  exported: false,
  error: false,
  incomplete: false
};

const orderDateEmptyValues = {
  from: "",
  to: ""
};

const emptyValuesPerFeature = {
  product: articleEmptyValues,
  stock: articleEmptyValues,
  prices: articleEmptyValues,
  type: typeEmptyValues,
  state: stateEmptyValues,
  orderDate: orderDateEmptyValues
};

const form = [
  {
    name: "OnlySuccess",
    label: i18n.t("salesChannelManagementApp.journal.onlySuccess"),
    type: "checkbox",
    status: "success",
    setFieldValue: [
      {
        name: "OnlyError",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      },
      {
        name: "OnlyNotExported",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  },
  {
    name: "OnlyError",
    label: i18n.t("salesChannelManagementApp.journal.onlyError"),
    type: "checkbox",
    status: "error",
    setFieldValue: [
      {
        name: "OnlySuccess",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      },
      {
        name: "OnlyNotExported",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  },
  {
    name: "OnlyNotExported",
    label: i18n.t("salesChannelManagementApp.journal.onlyNotExported"),
    type: "checkbox",
    status: "default",
    setFieldValue: [
      {
        name: "OnlyError",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      },
      {
        name: "OnlySuccess",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  },
  {
    name: "LastImportedFrom",
    label: i18n.t("salesChannelManagementApp.journal.from"),
    type: "datetime"
  },
  {
    name: "LastImportedTo",
    label: i18n.t("salesChannelManagementApp.journal.to"),
    type: "datetime"
  }
];

const formType = [
  {
    name: "OnlyVariants",
    label: i18n.t("salesChannelManagementApp.journal.typeOnlyVariants"),
    type: "checkbox",
    setFieldValue: [
      {
        name: "NoVariants",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  },
  {
    name: "NoVariants",
    label: i18n.t("salesChannelManagementApp.journal.typeNoVariants"),
    type: "checkbox",
    setFieldValue: [
      {
        name: "OnlyVariants",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  }
];

const formState = [
  {
    name: "Imported",
    label: i18n.t("salesChannelManagementApp.journal.states.imported"),
    type: "checkbox"
  },
  {
    name: "Error",
    label: i18n.t("salesChannelManagementApp.journal.states.error"),
    type: "checkbox"
  },
  {
    name: "Incomplete",
    label: i18n.t("salesChannelManagementApp.journal.states.incomplete"),
    type: "checkbox"
  }
];

const formOrderDate = [
  {
    name: "From",
    label: i18n.t("salesChannelManagementApp.journal.from"),
    type: "datetime"
  },
  {
    name: "To",
    label: i18n.t("salesChannelManagementApp.journal.to"),
    type: "datetime"
  }
];

const formTracking = [
  {
    name: "OnlyTracking",
    label: i18n.t("salesChannelManagementApp.journal.trackingOnlyTracking"),
    type: "checkbox",
    setFieldValue: [
      {
        name: "NoTracking",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  },
  {
    name: "NoTracking",
    label: i18n.t("salesChannelManagementApp.journal.trackingNoTracking"),
    type: "checkbox",
    setFieldValue: [
      {
        name: "OnlyTracking",
        dependsOnOwnValue: {
          setValue: false,
          values: [true]
        },
        dependsOnOwnValueNotSet: {
          setValue: true,
          values: [false]
        }
      }
    ]
  }
];

const formPerFeature = {
  product: form,
  stock: form,
  prices: form,
  type: formType,
  state: formState,
  orderDate: formOrderDate,
  tracking: formTracking
};

export {
  featuresAvailable,
  featureDefaultValues,
  formPerFeature,
  emptyValuesPerFeature
};
