var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.TableHelper,{key:'reporting-' + _setup.selectedFeature,ref:"reportingTable",attrs:{"actions":_setup.actions,"buttons":_setup.batchActions,"fields":_setup.fields[_setup.selectedFeature],"items":_setup.reportingData,"meta":_setup.meta,"no-route-params":true,"param-prefix":_setup.paramPrefix,"use-card":false,"actions-fixed":"","loading-key":"load-reporting-data","progress-bar-key":"load-reporting-data"},on:{"search":function($event){_setup.search = $event},"select-all-rows-in-data":function($event){_setup.allRowsSelected = $event},"reload-data":_setup.executeDataSet,"requeue-job":_setup.requeueJob,"requeue-jobs":function($event){_setup.requeueJobs(
        _setup.allRowsSelected,
        _vm.$refs.reportingTable?.selectedRows.map(row => row.orderId),
        _setup.quickFilterParameters,
        _setup.isFiltered
      )},"show-detail":_setup.showDetail},scopedSlots:_vm._u([(_setup.quickFilterFeatures?.[_setup.selectedFeature])?{key:"afterToolbar",fn:function(){return [_vm._l((_setup.quickFilterFeatures?.[_setup.selectedFeature]),function(feature){return _c(_setup.TableHelperQuickFilter,{key:feature,attrs:{"feature":feature},on:{"input":function($event){_setup.quickFilterModel = $event},"execute-filter":_setup.executeDataSet},model:{value:(_setup.quickFilterModel),callback:function ($$v) {_setup.quickFilterModel=$$v},expression:"quickFilterModel"}})}),(_setup.activeFilter)?_c('button',{staticClass:"btn btn-sm btn-hover-light-primary",on:{"click":_setup.resetFilter}},[_c('i',{staticClass:"fal fa-filter-slash p-0 fa-lg"})]):_vm._e()]},proxy:true}:null,{key:"beforeHeaderButtons",fn:function(){return [(_setup.BATCH_WORKFLOW_ID)?_c(_setup.TestForm):_vm._e()]},proxy:true}],null,true)}),_c(_setup.Modal,{attrs:{"type":"large","show-modal":_setup.showDetailModal,"title":_vm.$t('salesChannelManagementApp.reporting.title', {
        featureName: _vm.$t('salesChannelManagementApp.titles.' + _setup.selectedFeature)
      }).toString()},on:{"toggle-modal":function($event){_setup.showDetailModal = $event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_setup.TableHelper,{attrs:{"loading-key":"load-reporting-detail","progress-bar-key":"load-reporting-detail","param-prefix":_setup.paramPrefixDetail,"items":_setup.reportingDetailData,"fields":_setup.reportingDetailFields,"meta":_setup.reportingDetailMeta,"use-card":false,"no-route-param":true,"enable-select":false,"disable-search":true,"no-route-params":true},on:{"reload-data":_setup.showDetail,"show-detail":_setup.showDetail}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }