<script setup>
import { useReportingQuickFilter } from "@/components/ExternalApps/SalesChannelManagementApp/composables/Reporting/useReportingQuickFilter";
import { computed, defineEmits, defineProps, ref } from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

const { generateFeatureValues, getFormByFeature } = useReportingQuickFilter();
const filterDropdownAttach = ref(null);

const formConfig = {
  snippetPrefix: "tableHelper",
  showLabels: true,
  fullWidth: false,
  labelStacked: false,
  disableSkeletonLoading: true,
  enableVariables: false,
  enableTypecast: false,
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7"
};
const props = defineProps({
  value: {
    type: Object,
    default: () => {}
  },
  feature: {
    type: String,
    default: ""
  }
});
const show = ref(false);
const emit = defineEmits(["execute-filter", "reset-filter", "input"]);
const form = computed(() => getFormByFeature(props.feature));
const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  }
});
const activeFilter = computed(() => {
  let featureValues = Object.keys(model.value)?.filter(val =>
    val.startsWith(props.feature)
  );

  for (const val of featureValues) {
    if (model.value[val]) return true;
  }
  return false;
});

function executeFilter() {
  show.value = false;
  emit("execute-filter", props.feature);
}

function resetFilter() {
  emit("reset-filter", props.feature);
  model.value = { ...model.value, ...generateFeatureValues(props.feature) };
}
</script>

<template>
  <div class="table-helper-filter">
    <div>
      <button
        :class="{ active: show }"
        class="btn btn-sm btn-hover-light-primary"
        @click="show = true"
      >
        <span>
          {{ $t(`salesChannelManagementApp.reporting.entities.${feature}`) }}
        </span>
        <i v-if="activeFilter" class="fal fa-filter color-text fa-1x"></i>
      </button>
      <v-menu
        v-model="show"
        :attach="filterDropdownAttach"
        :close-on-content-click="false"
        content-class="table-helper-filter-content"
      >
        <div class="table-helper-filter-dropdown-inner min-w-600px">
          <div class="d-flex justify-content-between">
            <span class="font-weight-bold">
              {{
                $t(`salesChannelManagementApp.reporting.entities.${feature}`)
              }}
            </span>
            <button
              v-if="activeFilter"
              class="btn btn-sm btn-hover-light-primary"
              @click="resetFilter"
            >
              <i class="fal fa-filter-slash p-0"></i>
            </button>
          </div>
          <FormHelper
            v-model="model"
            :config="formConfig"
            :form="form"
            class="w-100"
          ></FormHelper>
          <div class="d-flex justify-content-end mt-2">
            <button class="btn btn-primary btn-sm" @click="executeFilter">
              <span>{{ $t("tableHelper.applyFilter") }}</span>
            </button>
          </div>
        </div>
      </v-menu>
    </div>
    <div ref="filterDropdownAttach" class="menu-attach position-relative"></div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.card) {
  max-width: 575px;
}

.color-text {
  color: $color-connect-text;
}

.table-helper-filter {
  position: relative;

  &-content {
    overflow: visible;
    contain: none;
    box-shadow: none;
  }

  &-dropdown {
    &-inner {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #e8edf3;
      box-shadow: #282d360d 0px 8px 20px;
      border-radius: 12px;
    }
  }
}
</style>
