<script setup>
import { useTesting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useTesting";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { ref } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import Modal from "@/components/Tools/Modal.vue";

const testForm = ref();
const { selectedFeature } = useFeatures();
const { showModal, form, formConfig, formData, testWorkflow } =
  useTesting(testForm);
</script>

<template>
  <div>
    <button class="btn btn-sm btn-primary" @click="showModal = true">
      {{ $t(`salesChannelManagementApp.test.import.${selectedFeature}`) }}
    </button>

    <Modal
      :show-modal="showModal"
      :title="
        selectedFeature === 'tracking'
          ? $t('salesChannelManagementApp.test.import.tracking')
          : $t('salesChannelManagementApp.test.title', {
              featureName: $t(
                'salesChannelManagementApp.titles.' + selectedFeature
              )
            })
      "
      @toggle-modal="showModal = $event"
    >
      <template #content>
        <FormHelper
          ref="testForm"
          v-model="formData"
          :config="formConfig"
          :form="form"
        />
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="testWorkflow">
          <template v-if="selectedFeature === 'tracking'">
            {{ $t("salesChannelManagementApp.test.export") }}
          </template>
          <template v-else>
            {{ $t("salesChannelManagementApp.test.start") }}
          </template>
        </button>
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
i {
  color: $color-connect-text;
}
</style>
