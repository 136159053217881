import { computed, getCurrentInstance, ref } from "vue";
import ProcessManager from "@/components/Workflows/processManager";
import { Error } from "@/core/plugins/swal";
import { useStore } from "@/core/services/store";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { salesOrderFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import { useUtility } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useUtility";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { transformParameters } from "@/components/ExternalApps/SalesChannelManagementApp/utility/reporting";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

const store = useStore();
const showModal = ref(false);
const formData = ref();
const { selectedFeature } = useFeatures();
const { selectedIntegrationName } = useIntegration();
const BATCH_WORKFLOW_ID = computed(() =>
  store.getters.testBatchWorkflowId(selectedFeature.value)
);
const { xentralProjectHeader } = useUtility();

export const useTesting = formComponent => {
  const i18n = getCurrentInstance().proxy.$i18n;
  const formConfig = ref({
    snippetPrefix: "salesChannelManagementApp",
    labelColClass: "col-5",
    fieldColClass: "col-7",
    fieldColClassXl: "col-7",
    ...labelData.value
  });
  const numberKeyPerApp = {
    woocommerce: {
      salesOrder: "orderId",
      tracking: "orderId"
    },
    shopify: {
      salesOrder: "orderNumberShopify",
      tracking: "orderNumberShopify"
    },
    tradebyte: {
      salesOrder: "orderIdTradebyte",
      tracking: "orderIdTradebyte"
    }
  };
  const numberKey = {
    prices: "productNumber",
    product: "productNumber",
    salesOrder: "orderNumber",
    stock: "productNumber",
    tracking: "orderNumber"
  };
  const currentNumberKey = computed(() => numberKey[selectedFeature.value]);
  const form = computed(() => {
    let formConfig = [];

    let label =
      numberKeyPerApp?.[store.getters.appType]?.[selectedFeature.value] ??
      currentNumberKey.value;

    let formField = {
      name: "number",
      type: "text",
      label: `test.${label}`,
      disableReturnType: true,
      validations: {
        required: true
      }
    };

    if (i18n.te(`salesChannelManagementApp.journal.${label}ImportInfoText`)) {
      formField.infoText = i18n.t(
        `salesChannelManagementApp.journal.${label}ImportInfoText`
      );
    }

    if (
      i18n.te(`salesChannelManagementApp.journal.${label}ImportPlaceHolder`)
    ) {
      formField.placeholder = i18n.t(
        `salesChannelManagementApp.journal.${label}ImportPlaceHolder`
      );
    }

    formConfig.push(formField);

    return formConfig;
  });

  async function testWorkflow() {
    if (formComponent.value.validate() !== true) {
      Error(i18n.t("salesChannelManagementApp.test.requiredFields"));
      return;
    }

    let params = {};

    if (formData.value?.number?.includes(",")) {
      params["orderNumbers"] = formData.value?.number
        .split(",")
        ?.map(n => n.trim())
        ?.filter(v => v.length > 0);
    } else {
      params["orderNumbers"] = [formData.value?.number];
    }

    if (selectedFeature.value === "prices") {
      params["addPricesToProduct"] = true;
    }

    showModal.value = false;
    await executeBatchWorkflow(params);
  }

  async function requeueJob(entry) {
    let orderId = entry?.item?.orderId;

    if (!orderId) return;

    const params = {
      orderIds: [orderId]
    };

    await executeBatchWorkflow(params);
  }

  async function requeueJobs(
    allRowsSelected,
    selectedRows,
    parameters = [],
    isFiltered = false
  ) {
    let params = {};

    if (!allRowsSelected && selectedRows?.length > 0) {
      params.orderIds = selectedRows;
    }

    if (allRowsSelected) {
      const paramsToTransform = parameters.filter(
        item => item.name !== "channelSpecificName"
      );
      params.parameters = transformParameters(paramsToTransform);
    }

    params.isFiltered = isFiltered;

    await executeBatchWorkflow(params);
  }

  async function executeBatchWorkflow(params) {
    addEventToLoadingQueue({
      key: "execute-batch-sync",
      group: "execute-batch-sync"
    });
    let parameters = {
      ...params,
      channelSpecificName: selectedIntegrationName.value,
      test: true
    };

    let headers = salesOrderFeatures.includes(selectedFeature.value)
      ? {}
      : xentralProjectHeader.value;

    try {
      const response = await ProcessManager.run(
        BATCH_WORKFLOW_ID.value,
        {
          params: parameters
        },
        headers
      );

      if (response?.data?.status === "process.error") {
        Error(response.data.content_identifier);
        removeEventFromLoadingQueue({
          key: "execute-batch-sync",
          group: "execute-batch-sync"
        });
        return;
      }

      removeEventFromLoadingQueue({
        key: "execute-batch-sync",
        group: "execute-batch-sync",
        type: "success",
        prefix: "salesChannelManagementApp",
        name: `reporting.executed.${selectedFeature.value}`
      });
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({
        key: "execute-batch-sync",
        group: "execute-batch-sync"
      });
    }
  }

  return {
    showModal,
    form,
    formConfig,
    formData,
    BATCH_WORKFLOW_ID,
    testWorkflow,
    requeueJob,
    requeueJobs
  };
};
