var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){_setup.showModal = true}}},[_vm._v(" "+_vm._s(_vm.$t(`salesChannelManagementApp.test.import.${_setup.selectedFeature}`))+" ")]),_c(_setup.Modal,{attrs:{"show-modal":_setup.showModal,"title":_setup.selectedFeature === 'tracking'
        ? _vm.$t('salesChannelManagementApp.test.import.tracking')
        : _vm.$t('salesChannelManagementApp.test.title', {
            featureName: _vm.$t(
              'salesChannelManagementApp.titles.' + _setup.selectedFeature
            )
          })},on:{"toggle-modal":function($event){_setup.showModal = $event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_setup.FormHelper,{ref:"testForm",attrs:{"config":_setup.formConfig,"form":_setup.form},model:{value:(_setup.formData),callback:function ($$v) {_setup.formData=$$v},expression:"formData"}})]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-primary",on:{"click":_setup.testWorkflow}},[(_setup.selectedFeature === 'tracking')?[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.test.export"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.test.start"))+" ")]],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }