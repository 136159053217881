import { computed } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import {
  emptyValuesPerFeature,
  featuresAvailable,
  formPerFeature
} from "@/components/ExternalApps/SalesChannelManagementApp/config/configQuickFilter";

export const useReportingQuickFilter = () => {
  const { features } = useFeatures();

  const activeFilterFeatures = computed(() => {
    let featureList = [];

    for (const feature of Object.values(featuresAvailable)) {
      if (!features.value.includes(feature)) continue;
      featureList.push(feature);
    }

    featureList.push("type");

    return featureList;
  });

  function getFormByFeature(feature) {
    let featureForm = formPerFeature[feature];

    // Create a deep copy of the form array to avoid mutating the original data
    const formCopy = JSON.parse(JSON.stringify(featureForm));

    formCopy.forEach(field => {
      // Update the main field name
      field.name = `${feature}${field.name}`;

      // Update nested setFieldValue names if present
      if (field.setFieldValue) {
        field.setFieldValue.forEach(setField => {
          setField.name = `${feature}${setField.name}`;
        });
      }
    });

    return formCopy;
  }

  function generateFeatureValues(feature) {
    const result = {};
    const featureEmptyValues = emptyValuesPerFeature[feature];

    for (const key in featureEmptyValues) {
      result[`${feature}${key.charAt(0).toUpperCase() + key.slice(1)}`] =
        featureEmptyValues[key];
    }

    return result;
  }

  function generateFeatureObject(defaultValues) {
    const result = {};

    featuresAvailable.forEach(feature => {
      if (feature === "type") {
        result.typeOnlyVariants = false;
        result.typeNoVariants = false;
      } else if (feature === "tracking") {
        result.trackingOnlyVariants = false;
        result.trackingNoVariants = false;
      } else {
        for (const key in defaultValues) {
          result[`${feature}${key.charAt(0).toUpperCase() + key.slice(1)}`] =
            defaultValues[key];
        }
      }
    });

    return result;
  }

  return {
    getFormByFeature,
    generateFeatureValues,
    generateFeatureObject,
    activeFilterFeatures
  };
};
